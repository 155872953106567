
import { defineComponent, ref, onMounted, watch, inject } from 'vue'
import ShopDialog from './ShopDialog.vue'
import { goShopList, goShopDelete } from '@/service/main/main'
import { ElMessageBox, ElMessage } from 'element-plus'
export default defineComponent({
  props: ['searchInput'],
  components: { ShopDialog },
  async setup(props) {
    // 刷新页面
    const reload: any = inject('reload')
    let dialogVisible = ref(false)
    let ShopDialog = ref()
    let goodsId = ref()
    onMounted(() => {
      return ShopDialog
    })
    // eslint-disable-next-line vue/no-setup-props-destructure
    let searchInputProps = ''
    watch(
      () => props.searchInput,
      (newValue, oldValue) => {
        console.log({ newValue, oldValue })
        searchInputProps = newValue
        goList(newValue)
      },
    )
    // 获取商品列表
    let shopList = ref()
    const currentPage = ref(1)
    let shopRes = ref()
    // 向上取整页码书
    // 每页页数
    const pages = 5
    let totalPage = ref()
    const goList = async (searchInputProps: any) => {
      // 分页
      shopRes.value = await goShopList(
        searchInputProps,
        currentPage.value - 1,
        pages,
        2,
      )
      totalPage.value = Math.ceil(shopRes.value.Total / pages) * 100
      shopList.value = shopRes.value.List
      console.log(shopList)
    }
    goList(searchInputProps)

    const handleCurrentChange = async (val: any) => {
      currentPage.value = val
      let shopRes = await goShopList(
        searchInputProps,
        currentPage.value - 1,
        pages,
        2,
      )
      shopList.value = shopRes.List
      console.log(shopList.value)
    }
    const handleModify = ($index: any, row: any) => {
      // console.log($index, row)
      ShopDialog.value.dialogFormVisible = true
      goodsId.value = row.GoodsId
    }

    // 删除
    const open = (goodsId: number | string) => {
      ElMessageBox.confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          goShopDelete(goodsId)
          dialogVisible.value = false
          ElMessage({
            type: 'success',
            message: '删除成功!',
          })
          // 刷新页面
          reload()
        })
        .catch(() => {
          ElMessage({
            type: 'info',
            message: '已取消删除',
          })
        })
    }
    const handleDelete = (row: any) => {
      open(row.GoodsId)
    }

    return {
      shopList,
      shopRes,
      goList,
      dialogVisible,
      ShopDialog,
      goodsId,
      currentPage,
      totalPage,
      searchInputProps,
      handleDelete,
      handleModify,
      handleCurrentChange,
    }
  },
})
