
import { defineComponent, ref } from 'vue'
import ShopListWare from './cpns/ShopListWare.vue'
import { MainHeader } from '@/components/main-header/index'
export default defineComponent({
  components: { MainHeader, ShopListWare },
  setup() {
    let searchInput = ref()
    const inputChange = (e: any) => {
      // console.log(e)
      searchInput.value = e
    }
    return { inputChange, searchInput }
  },
})
