import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_ShopListWare = _resolveComponent("ShopListWare")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_MainHeader, {
      onInputChange: _ctx.inputChange,
      placeholder: "搜索商品名称……",
      title: "已入库商品"
    }, null, 8, ["onInputChange"]),
    _createVNode(_component_ShopListWare, { searchInput: _ctx.searchInput }, null, 8, ["searchInput"])
  ]))
}