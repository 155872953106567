
import { defineComponent, reactive, toRefs, ref } from 'vue'
import { modifyPrice } from '@/service/main/main'
export default defineComponent({
  props: {
    goodsId: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    let dialogFormVisible = ref(false)
    let form = reactive({
      priceType: '1',
      region: '',
      price: '',
      price2: '',
    })
    // 修改价格请求
    const goModifyPrice = () => {
      dialogFormVisible.value = false
      modifyPrice(form.priceType, form.price, form.price2, props.goodsId)
    }

    return {
      dialogFormVisible,
      form,
      goModifyPrice,
    }
  },
})
